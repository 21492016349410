<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <!-- <el-row :gutter="20">
          <el-col
              v-if="getRoleSlug === 'superadmin'"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                :error="formErrors.admin"
                label="Admin"
                prop="admin"
                :label-width="formLabelWidth"
              >
                <el-select v-model="formData.admin" @change="handleChangeAdmin" placeholder="Select">
                  <el-option
                     v-for="item in listAdmins"
                      :key="item.master"
                      :label="item.name"
                      :value="item.master"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
        </el-row> -->
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.script"
              label="Script"
              prop="script"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="formData.script"
                clearable
                filterable
                placeholder="Select"
                disabled
              >
                <el-option
                  v-for="item in listScripts"
                  :key="item.stock_id"
                  :label="item.name"
                  :value="item.stock_id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.user_id"
              label="User Id"
              prop="user_id"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="formData.user_id"
                clearable
                filterable
                placeholder="Select"
                disabled
              >
                <el-option
                  v-for="item in listTradingClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="20">
           <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.segment"
              label="Segment"
              prop="segment"
              :label-width="formLabelWidth"
            >
              <br />
              <b>{{formData.segment}} </b>
              <!-- <el-radio-group v-model="formData.segment" disabled>
                <el-radio :label="'mcx'">MCX</el-radio>
                <el-radio :label="'equity'">Equity</el-radio>
                <el-radio :label="'NSE'">NSE</el-radio>
              </el-radio-group> -->
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.unit"
              label="Units"
              prop="unit"
              :label-width="formLabelWidth"
            >
             <el-input v-model="formData.unit" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.buy_rate"
              label="Buy Rate"
              prop="buy_rate"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.buy_rate" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.sell_rate"
              label="Sell Rate"
              prop="sell_rate"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.sell_rate" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-if="getRoleSlug === 'admin' || getRoleSlug === 'broker'">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.transaction_password"
              label="Transaction Password"
              prop="transaction_password"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="formData.transaction_password"
                type="password"
                autocomplete="off"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- {{formData}} -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <el-button type="info" @click="sendChildFormData()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
import { fetchAdminAllowedStockList } from "@/api/market-watch";
import { fetchList as tradingClientsList } from "@/api/trading-client";
import { edit } from "@/api/trade";
import { fetchAllAdminList, adminClients } from "@/api/super-admin/admin";
import { mapGetters } from "vuex";
import getRoleSlug from "@/store/app.js";

export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    listAdmins: {
      type: Array,
      default: function () {
        return [];
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
      listScripts: {},
      listTradingClients: {},
      //listAdmins: {},
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),

    dialogTitle() {
      return this.dialogType === "create"
        ? "Add Trade"
        : "Edit Trade";
    },
  },
   created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.fetchData(this.$route.params.id);
    this.formRules.script = requiredRule("script");
    this.formRules.user_id = requiredRule("user_id");
    this.formRules.unit = requiredRule("unit");
    this.formRules.buy_rate = requiredRule("buy_rate");
    this.formRules.sell_rate = requiredRule("sell_rate");
    // this.formRules.buy_rate = numberRule("buy_rate", ["required"]);
    // this.formRules.sell_rate = numberRule("sell_rate", ["required"]);
    this.formRules.transaction_password = requiredRule("transaction_password");


    //this.getAdminList()
    if(getRoleSlug.state.roleSlug == 'admin' ) {
       this.getTradingClientList()
      // this.getStockList()
    }
  },
  methods: {
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {

      if(this.formData.buy_rate == 0 && this.formData.sell_rate == 0) {
        this.flashError("Please Enter Rate");
      } else {
        this.loading = true;
          console.log("form",this.formData)
        this.$refs.refForm.validate((valid) => {
          if (valid) {
            this.formData.trade_id
            // WHEN EDIT MERGE NEW PARAMETERS
            if (this.dialogType === "edit") {
              this.formData = Object.assign(this.formData, {
                dialog_type: this.dialogType,
              });
            }
            this.$emit("getChildFormData", this.formData);
            this.loading = false;
          }
        });
      }
    },

    getTradingClientList() {
      tradingClientsList().then((response) => {
        this.listTradingClients  = response.data.data.data;
      });
    },

    ///////////////////
    //// EDIT
    ///////////////////
    fetchData(id){
      if(id) {
        this.fetchEditData(id)
      }
    },
    // fetch by id
    fetchEditData(id) {
      // this.loading = true;
      edit(id)
        .then((response) => {
          this.formData = response.data.data;
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.config = response.data.data.config;
            this.equity = response.data.data.equity;
            this.mcx = response.data.data.mcx;
            this.opt = response.data.data.opt;
            // this.loading = false;
          } else {
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    getStockList() {
      let dataPost = {
        adminId: this.formData.admin ? this.formData.admin : null
      }
      fetchAdminAllowedStockList(dataPost).then((response) => {
        this.listScripts = response.data.data.all;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleChangeAdmin(id) {
      // console.log(id);
      adminClients(id).then((response) => {
        this.listTradingClients  = response.data.data;
      });
      this.getStockList();
    }
  },
};
</script>
