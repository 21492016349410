import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: '/clients',
    method: 'get',
    params: query
  })
}
export function clientsQtySettingList(query) {
  return request({
    url: '/clients/qty-setting-list',
    method: 'get',
    params: query
  })
}

export function show(id) {
  return request({
    url: `clients/view/`,
    method: 'get',
    params: { id }
  })
}

export function store(data) {
  return request({
    url: '/clients/store',
    method: 'post',
    data
  })
}

export function edit(id) {
  return request({
    url: `clients/edit/`,
    method: 'get',
    params: { id }
  })
}

export function showClientDetails(id) {
  return request({
    url: `clients/details-show/`,
    method: 'get',
    params: { id }
  })
}

export function fetchListCloseTrades(query) {
  return request({
    url: '/clients/closed/trade',
    method: 'get',
    params: query
  })
}

export function refreshAccount(data) {
  return request({
    url: '/clients/refresh',
    method: 'post',
    data
  })
}

export function duplicateAccount(data) {
  return request({
    url: '/clients/accountduplicate',
    method: 'post',
    data
  })
}
export function passwordUpdate(data) {
  return request({
    url: '/clients/updatepassword',
    method: 'post',
    data
  })
}



export function update(data) {
  return request({
    url: '/clients/update',
    method: 'post',
    data
  })
}
export function downloadClientPLList(query) {
  return request({
    url: '/clients/pl-list-download',
    method: 'get',
    params: query
  })
}

// export function update(id, data) {
//   return request({
//     url: `clients/${id}`,
//     method: 'put',
//     data
//   })
// }

// export function destroy(id) {
//   return request({
//     url: `clients/${id}`,
//     method: 'delete'
//   })
// }

export function destroy(id) {
  return request({
    url: `clients/delete/`,
    method: 'POST',
    params: { id }
  })
}
export function logout(id) {
  return request({
    url: `clients/logout/`,
    method: 'get',
    params: { id }
  })
}

export function downloadBackupStorage(data) {
  return request({
    url: '/clients/downloadBackupStorage',
    method: 'post',
    data
  })
}

export function downloadWeeklyBillsGenerated(data) {
  return request({
    url: '/clients/downloadWeeklyBillsGenerated',
    method: 'post',
    data
  })
}
