<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Trade Details</h4>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="search-wrapper"></div>
      </el-col>
    </el-row>
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <span style="color: #fff">Config</span>
          <br /><br />
          <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>
                    ID
                  </p>
                </th>
                <td>
                  <p>
                    {{viewData.stock_id }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>
                    Script 
                  </p>
                </th>
                <td>
                  <p>
                    {{ viewData.script}}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Segment :</p>
                </th>
                <td>
                  <p>
                    {{ viewData.segment}}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>User :</p>
                </th>
                <td>
                  <p>
                    {{ viewData.client_name}}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Buy Rate :</p>
                </th>
                <td>
                  <p>
                    {{ viewData.buy_rate}}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Sell Rate :</p>
                </th>
                <td>
                  <p>
                    {{ viewData.sell_rate}}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Lots/Units :</p>
                </th>
                <td>
                  <p>
                    {{ viewData.lot_and_unit}}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Brought At :</p>
                </th>
                <td>
                  <p>
                    {{ viewData.buy_date}}
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Sold At :</p>
                </th>
                <td>
                  <p>
                    {{ viewData.sell_date}}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </el-card>
    </div>
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import { edit, update, destroy as deleteTrade, restoreBuy, restoreSell, tradeUpdate } from "@/api/trade";
import AddEditDialog from "@/views/trades/components/AddEditDialog.vue";
import getRoleSlug from "@/store/app.js";


export default {
  name: "TradeDetails",
  components: {
    AddEditDialog
  },
  data() {
    return {
      loading: false,
      viewData: {},
      config: {},
      equity: {},
      mcx: {},
      dialogVisible: false,
      dialogType: "create",
      formData: {},
      formErrors: [],
    };
  },
  computed: {},
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.getPendingOrderDetails();
  },
  methods: {
    // //////////////////
    // GET DATA
    // //////////////////
    getPendingOrderDetails() {
      this.loading = true;
      edit(this.$route.params.id).then((response) => {
        this.viewData = response.data.data;
        console.log(this.viewData);
        this.loading = false;
      });
    },
    handleUpdateData() {
      this.handleEdit(this.$route.params.id)
    },
    handleDeleteData() {
      this.handleDelete(this.$route.params.id)
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleRestoreBuy() {
      console.log('on working')
      this.loading = true
      let dataPost = {
        trade_id : this.viewData.trade_id
      }
       restoreBuy(dataPost)
            .then((response) => {
              if (response.data.success === true) {
                this.flashSuccess(response.data.message);
                this.getPendingOrderDetails();
                this.loading = false;
              } else {
                this.flashError(response.data.message);
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });

    },
    handleRestoreSell() {
      console.log('on working')
      this.loading = true
      let dataPost = {
        trade_id : this.viewData.trade_id
      }
       restoreSell(dataPost)
            .then((response) => {
              if (response.data.success === true) {
                this.flashSuccess(response.data.message);
                this.getPendingOrderDetails();
                this.loading = false;
              } else {
                this.flashError(response.data.message);
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });

    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      console.log(data);
      tradeUpdate(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.loading = false;
            this.flashSuccess(response.data.message);
            // this.getPendingOrderDetails();
          } else {
            this.flashError(response.data.message);
            // this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });

    },
    // //////////////////
    // EDIT
    // //////////////////
    handleEdit(id) {
      this.fetchData(id);
    },
    fetchData(id) {
      this.loading = true;
      edit(id)
        .then((response) => {
          this.formData = response.data.data;
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.loading = false;
            this.dialogType = "edit";
            this.dialogVisible = true;
          } else {
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    handleUpdate(data) {
      this.loading = true;
      update(data.id, data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.loading = false;
            this.flashSuccess(response.data.message);
            this.getPendingOrderDetails();
          } else {
            this.flashError(response.data.message);
            // this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(id) {
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        deleteTrade(id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
             this.$router.push(
                    "/" + getRoleSlug.state.roleSlug + "/trades"
                  );
                location.reload()
            this.loading = false;
          }
        });
      });
    },
    
  },
};
</script>
