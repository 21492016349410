import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: 'trades',
    method: 'get',
    params: query
  })
}

export function fetchChamkaList(query) {
  return request({
    url: 'chamka',
    method: 'get',
    params: query
  })
}

export function show(stock_id) {
  return request({
    url: `trades-by-stock/`,
    method: 'get',
    params: { stock_id }
  })
}

export function store(data) {
  return request({
    url: '/trades',
    method: 'post',
    data
  })
}

// export function edit(id) {
//   return request({
//     url: `trades/edit/${id}`,
//     method: 'get'
//   })
// }

export function edit(id) {
  return request({
    url: `trades/edit/`,
    method: 'get',
    params: { id }
  })
}

export function update(data) {
  return request({
    url: '/trades/update',
    method: 'post',
    data
  })
}



export function destroy(id) {
  return request({
    url: `trades/delete/`,
    method: 'POST',
    params: { id }
  })
}


export function restoreBuy(data) {
  return request({
    url: '/trades/restore/buy',
    method: 'post',
    data
  })
}

export function restoreSell(data) {
  return request({
    url: '/trades/restore/sale',
    method: 'post',
    data
  })
}

export function tradeUpdate(data) {
  return request({
    url: '/trades/update',
    method: 'post',
    data
  })
}

export function tradeExport(query) {
  return request({
    url: '/trades/tradeExport',
    method: 'get',
    params: query
  })
}

